import classNames from 'classnames';
import { useRef, MouseEvent, ReactNode } from 'react';
import { useTenant } from '~/tenants/common/TenantContextProvider';

export interface ButtonProps {
  icon?: ReactNode;
  children?: ReactNode;
  ping?: boolean;
  variant?: 'primary' | 'secondary' | 'outline' | 'transparent' | 'success' | 'tenant';
  type?: 'button' | 'submit';
  right?: boolean;
  size?: 'slim' | 'short' | 'standard';
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export default function Button(props: ButtonProps) {
  const tenant = useTenant();
  const showTooltip = typeof props.disabled === 'string';

  //const isRight = props.icon && [IconType.ChevronRight, IconType.ArrowRight].includes(props.icon);

  const ref = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={ref}
      type={props.type || 'button'}
      tabIndex={-1}
      disabled={props.disabled}
      onClick={
        !showTooltip
          ? (e) => {
              if (props.disabled) {
                e.preventDefault();

                return;
              }

              if (!props.onClick) {
                return;
              }

              props.onClick(e);

              try {
                ref.current?.blur(); // prevent focus css from staying active
              } catch (ex) {
                // ok if it unmounted
              }
            }
          : undefined
      }
      style={
        props.variant === 'tenant' && !props.disabled
          ? {
              backgroundColor: tenant.background,
              borderColor: tenant.background,
            }
          : {}
      }
      className={classNames(
        'focus:outline-none rounded-theme inline-flex border items-center whitespace-nowrap tracking-wide transition-all duration-300 select-none leading-6 justify-center max-w-full min-w-7 group',
        {
          'opacity-30 pointer-events-none text-gray-600 bg-gray-300': props.disabled,
          'border-transparent': props.variant === 'transparent' && !props.disabled,
          'text-gray-700 bg-gray-100 border-gray-300 hover:bg-gray-50':
            props.variant === 'secondary' && !props.disabled,
          'text-gray-600 hover:text-gray-900 border-gray-500 hover:ring-1 hover:border-black hover:ring-black':
            props.variant === 'outline' && !props.disabled,
          'text-white border-gray-800 bg-gray-800 hover:bg-gray-600':
            (!props.variant || props.variant === 'primary') && !props.disabled,
          'text-white hover:brightness-110': props.variant === 'tenant' && !props.disabled,
          'text-white bg-green-600 border-green-600': props.variant === 'success' && !props.disabled,
          'text-sm font-medium': !props.size || props.size === 'standard',
          'py-1.5 px-2 md:py-2.5 md:px-4': !!props.children && (!props.size || props.size === 'standard'),
          'text-xs uppercase tracking-wider font-bold': props.size === 'short' || props.size === 'slim',
          'py-2': props.size === 'short',
          'py-1': props.size === 'slim',
          'px-2': (props.size === 'short' && !props.icon) || props.size === 'slim',
          'p-1': !props.children && (!props.size || props.size === 'standard'),
        },
      )}
    >
      {props.ping && (
        <span className="animate-ping absolute inline-flex h-full w-full rounded-lg bg-black opacity-75 pointer-events-none top-0 left-0 -z-10"></span>
      )}
      {props.icon ? (
        <span
          className={classNames('inline-block flex-shrink-0 overflow-hidden group-hover:scale-125 transition', {
            'w-7 h-3': props.size === 'short',
            'size-4': !props.size || props.size === 'standard',
            'size-3': props.size === 'slim',
          })}
        >
          {props.icon}
        </span>
      ) : (
        <></>
      )}
      {props.children && (
        <span
          className={classNames('leading-normal overflow-hidden inline-block', {
            'order-first pr-2': !!props.icon && !!props.right,
            'pl-2': !!props.icon && !props.right && props.size !== 'short',
          })}
        >
          <span
            className={classNames({
              'pr-2 inline-block': !!props.icon && !props.right && props.size === 'short',
            })}
          >
            {props.children}
          </span>
        </span>
      )}
    </button>
  );
}
